<template>
  <div class="check-role">
    <div class="content-shell">
      <div class="avatar">
        <img src="./img/avatar.png" alt="">
      </div>
      <p class="title">HI，主人。我是AI晓知因!</p>
      <p class="sub-title">请选择您的产业智能小助手进行角色创建</p>
      <el-select v-model="selectRole" placeholder="请选择所在行业">
        <el-option
          v-for="item in rolesList"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>

      <div class="create-btn">创建</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectRole: '',
      rolesList: []
    }
  }
}
</script>
<style lang="less" scoped>
.check-role {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url('./img/bg.png') no-repeat center center;
  .content-shell {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 449px;
    height: 533px;
    padding: 106px 62px 48px;
    background: #FFFFFF;
    box-shadow: 0px 28px 35px 0px rgba(0,52,184,0.21);
    .avatar {
      position: absolute;
      top: -78px;
      left: 50%;
      transform: translateX(-50%);
      padding: 16px;
      background: #FFFFFF;
      text-align: center;
      box-shadow: 0px 4px 12px 0px rgba(0,52,184,0.21);
      border-radius: 50%;
    }
    .title {
      font-size: 28px;
      color: #2A90FF;
    }
    .sub-title {
      margin: 16px 0 32px;
      font-size: 18px;
      color: #1C2236;
    }
    /deep/.el-select {
      width: 100%;
      .el-input__inner {
        height: 48px;
        line-height: 48px;
      }
    }

    .create-btn {
      margin-top: 170px;
      line-height: 48px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      background: linear-gradient(225deg, #6EA4FF 0%, #0988F6 100%);
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>
